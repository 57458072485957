interface Props {
  input: string;
  len: number;
}

export const truncateText = ({ input, len }: Props) => {
  if (input.split(" ").length > len && input.length > 0) {
    const new_words = input.split(" ");
    const first_words = [];

    for (let i = 0; i < new_words.length; i++) {
        if (i <= len) {
            first_words.push(new_words[i]);
        }
    }

    const new_str = first_words.join(" ");

    return new_str + '<span class="text-ellipsis">...</span>';
  }

  return input;
};